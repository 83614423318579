import React, { useContext, useRef, useEffect, useState } from "react"
import { graphql } from "gatsby"

import Link from 'components/globals/link'

import { MainContext } from "context/context"
import PageWrapper from 'components/globals/pageWrapper'
import ProjectRepeater from 'components/globals/projectRepeater'
import FadeCollapse from 'components/globals/fadeCollapse'
import Button from 'components/globals/button'

import GenericTextHero from "components/globals/genericTextHero"
import ProjectsGrid from 'components/flexibleContent/projectsGrid'

import { stripHtml, useIsMobile } from "utils"
import ParallaxSection from "components/globals/parallaxSection"

export default ({ data }) => {
    const {
        project: {
            databaseId,
            servicesRendered,
            industries,
            projectFields: {
                websiteUrl,
                labsProject,
                subtitle,
                content,
                openingParagraph,
                theRest,
                relatedProjects,
                backgroundColour,
                heroBackgroundImage,
                mobileHeroImage,
                extraScripts
            },
            title,
            featuredImage: _featuredImage
        },
    } = data.siteData

    const featuredImage = _featuredImage ? _featuredImage.node : null

    const {
        addInvertedHeaderSection,
        recalcLocomotive
    } = useContext(MainContext)

    const elementRef = useRef(null)

    const isMobile = useIsMobile()

    const [hasMounted, setHasMounted] = useState(false)
    const [isCopyExpanded, setIsCopyExpanded] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            if (elementRef && elementRef.current) {
                alert('test')
                addInvertedHeaderSection(elementRef.current)
            }

            setHasMounted(true)

            const imageHeroIfExists = document.querySelector('.single-project-hero .jarallax')

            if (imageHeroIfExists) {
                addInvertedHeaderSection(imageHeroIfExists)
            }
        }, 100)

        setTimeout(() => {
            recalcLocomotive()
        }, 4000)

        setTimeout(() => {
            recalcLocomotive()
        }, 2000)

        setTimeout(() => {
            recalcLocomotive()
        }, 8000)

        if (labsProject) {
            setTimeout(() => {
                const cursorWrapper = document.getElementById('cursor-wrapper')
                cursorWrapper.classList.add('inverted')
            }, 300)
        }
    }, [elementRef])

    const handleShowMore = () => {
        setIsCopyExpanded(!isCopyExpanded)
    }

    const meta = [
        {
            label: 'Industry',
            values: industries
        }, {
            label: 'Services',
            values: servicesRendered
        }
    ]

    const heroContent = <>
        <Link className={'back-link has-cursor-effect'} to={`/our-work`}>
            <i className={'fe fe-arrow-left'} />
            <span>
                Back to Our Work
            </span>
        </Link>
        <h1 className="big">
            <span dangerouslySetInnerHTML={{ __html: title }} />
        </h1>
    </>

    return (
        <>
            <PageWrapper
                title={title}
                description={stripHtml('')}
                image={featuredImage ? featuredImage.mediaItemUrl : null}
            >
                <section
                    className={`single-project-hero ${hasMounted ? 'mounted' : ''} ${labsProject ? 'labs-project inverted-cursor' : ''}`}
                    style={{ backgroundColor: backgroundColour ? backgroundColour : '' }}
                    data-scroll-section
                    ref={labsProject ? ref => elementRef.current = ref : null}
                >
                    <div className={'container deep'}>
                        {heroBackgroundImage ?
                            <ParallaxSection
                                backgroundUrl={(() => {
                                    if (isMobile && mobileHeroImage) {
                                        return mobileHeroImage.mediaItemUrl
                                    }

                                    if (heroBackgroundImage?.mediaItemUrl) {
                                        return heroBackgroundImage.mediaItemUrl
                                    }

                                    return ''
                                })()}
                                className={'container deep'}
                            >
                                {heroContent}
                            </ParallaxSection>
                            :
                            <>
                                {heroContent}
                            </>
                        }
                        <div className={'columns'}>
                            <div className={'content'}>
                                <div className={'copy huge'} dangerouslySetInnerHTML={{ __html: subtitle }} />
                                <div className={'copy openingParagraph'} dangerouslySetInnerHTML={{ __html: openingParagraph }} />
                                <FadeCollapse open={isCopyExpanded}>
                                    <div className={'copy'} dangerouslySetInnerHTML={{ __html: theRest }} />
                                </FadeCollapse>
                                <div className="buttons">
                                    {theRest &&
                                        <Button
                                            label={`See ${isCopyExpanded ? 'less' : 'more'}`}
                                            icon={'plus'}
                                            onClick={handleShowMore}
                                            inverted={!labsProject}
                                            compact
                                        />
                                    }
                                    {websiteUrl ?
                                        <Button
                                            label={`View website`}
                                            url={websiteUrl}
                                            inverted
                                            compact
                                        />
                                        : ''}
                                </div>
                            </div>
                            <div className={'meta'}>
                                {meta.map(item => {
                                    return (
                                        <div key={item.label}>
                                            <p className={'medium'}>
                                                {item.label}
                                            </p>
                                            <ul>
                                                {item.values.nodes.map(value => {
                                                    return (
                                                        <li key={value.name} className={'medium'}>
                                                            {value.name}
                                                        </li>

                                                    )
                                                })}
                                            </ul>

                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <div data-scroll-section>
                    <div className={`single-project-components${labsProject ? ' inverted-cursor' : ''}`} style={{ backgroundColor: backgroundColour ? backgroundColour : '' }}>
                        <ProjectRepeater rows={content} />
                    </div>
                    <section className={`ProjectsGrid${labsProject ? ' inverted-cursor' : ''}`} data-scroll data-scroll-class={'scrolled-into-view'} data-scroll-offset={"5%, 5%"}>
                        <ProjectsGrid
                            thirds
                            // includeAllProjects
                            currentProjectId={databaseId}
                            projectsSelected={relatedProjects}
                        />
                    </section>
                    <GenericTextHero labsProject={labsProject} />
                </div>
            </PageWrapper>
            {extraScripts ?
                <>
                    <div dangerouslySetInnerHTML={{ __html: extraScripts }} />
                </>
                : null}
        </>
    )
}


export const query = graphql`
query($databaseId: ID!) {
    siteData {
        project(id: $databaseId, idType: DATABASE_ID) {
            id
            databaseId
            title
            slug
            date
            link
            content(format: RENDERED)
            featuredImage {
                node {
                    altText
                    title(format: RENDERED)
                    mediaItemUrl
                    slug
                }
            }
            servicesRendered {
                nodes {
                    name
                }
            }
            industries {
                nodes {
                    name
                }
            }
            projectFields {
                colour
                heroBackgroundImage {
                    mediaItemUrl
                }
                mobileHeroImage {
                    mediaItemUrl
                }
                labsProject
                subtitle
                openingParagraph
                theRest
                websiteUrl
                prominentUrl
                extraScripts
                content {
                    copy
                    image {
                        mediaItemUrl
                    }
                    images {
                        image {
                            mediaItemUrl
                        }
                    }
                    leftImage {
                        mediaItemUrl
                    }
                    leftVideo {
                        mediaItemUrl
                    }
                    rightImage {
                        mediaItemUrl
                    }
                    rightVideo {
                        mediaItemUrl
                    }
                    leftImage2 {
                        mediaItemUrl
                    }
                    rightImage2 {
                        mediaItemUrl
                    }
                    noAutoplay
                    title
                    type
                    videoFileMp4 {
                        mediaItemUrl
                    }
                }
                relatedProjects {
                    project {
                        ...on WPGraphQL_Project {
                            databaseId
                            title
                            link
                            industries {
                                nodes {
                                    name
                                    databaseId
                                }
                            }
                            servicesRendered {
                                nodes {
                                    name
                                    databaseId
                                }
                            }
                            featuredImage {
                                node {
                                    mediaItemUrl
                                }
                            }
                        }
                    }
                }
                backgroundColour
            }
        }
    }
}
`