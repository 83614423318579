import React from 'react'

export default ({
    rows
}) => {
    let titleIncrementer = 0

    return (
        <div className={'project-repeater container deep'}>
            { rows && rows.map((row, index) => {
                const {
                    copy,
                    image,
                    images,
                    leftImage,
                    leftVideo,
                    rightImage,
                    rightVideo,
                    leftImage2,
                    rightImage2,
                    noAutoplay,
                    title,
                    type,
                    videoFileMp4
                } = row

                let content = null

                switch (type) {
                    case 'image':
                        if (image) {
                            content = (
                                <div>
                                    <img alt={`Case study image for a Your Creative case study.`} src={image.mediaItemUrl} />
                                </div>
                            )
                        }
                        break
                    case 'title-content':
                        titleIncrementer++

                        content = (
                            <>
                                <div className={'title'}>
                                    <figure>
                                        0{titleIncrementer}.
                                    </figure>
                                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                                </div>
                                <div className={'copy medium'} dangerouslySetInnerHTML={{ __html: copy }} />
                            </>
                        )
                        break
                    case 'title':
                        titleIncrementer++

                        content = (
                            <>
                                <div className={'title solo'}>
                                    <figure>
                                        0{titleIncrementer}.
                                    </figure>
                                    <h2 dangerouslySetInnerHTML={{ __html: title }} className="h2 small"/>
                                </div>
                            </>
                        )
                        break
                    case 'images':
                        content = (
                            <>
                                <div>
                                    {
                                        leftVideo ?
                                            <video muted loop autoPlay playsInline>
                                                <source src={leftVideo.mediaItemUrl} type="video/mp4" />
                                            </video>
                                        :
                                        <img src={leftImage && leftImage.mediaItemUrl} />
                                    }
                                </div>
                                <div>
                                    {
                                        rightVideo ?
                                            <video muted loop autoPlay playsInline>
                                                <source src={rightVideo.mediaItemUrl} type="video/mp4" />
                                            </video>
                                        :
                                        <img src={rightImage && rightImage.mediaItemUrl} />
                                    }
                                </div>
                            </>
                        )

                        break
                    case 'images3':
                        content = (
                            <>
                                <div className={ ( !leftImage && !leftImage2 ) ? 'empty': '' }>
                                    { leftImage &&
                                        <div>
                                            <img src={leftImage && leftImage.mediaItemUrl} />
                                        </div>
                                    }
                                    { leftImage2 &&
                                        <div>
                                            <img src={leftImage2 && leftImage2.mediaItemUrl} />
                                        </div>
                                    }
                                </div>
                                { rightImage &&
                                    <div>
                                        <img src={rightImage && rightImage.mediaItemUrl} />
                                    </div>
                                }
                            </>
                        )

                        break
                    case 'images3_2':
                        content = (
                            <>
                                { leftImage &&
                                    <div>
                                        <img src={leftImage && leftImage.mediaItemUrl} />
                                    </div>
                                }
                                <div className={ ( !rightImage && !rightImage2 ) ? 'empty': '' }>
                                    { rightImage &&
                                        <div>
                                            <img src={rightImage && rightImage.mediaItemUrl} />
                                        </div>
                                    }
                                    { rightImage2 &&
                                        <div>
                                            <img src={rightImage2 && rightImage2.mediaItemUrl} />
                                        </div>
                                    }
                                </div>
                            </>
                        )

                        break
                    case 'images4':
                        content = (
                            <>
                                <div>
                                    <div>
                                        <img src={leftImage && leftImage.mediaItemUrl} />
                                    </div>
                                    <div>
                                        <img src={leftImage2 && leftImage2.mediaItemUrl} />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img src={rightImage && rightImage.mediaItemUrl} />
                                    </div>
                                    <div>
                                        <img src={rightImage2 && rightImage2.mediaItemUrl} />
                                    </div>
                                </div>
                            </>
                        )

                        break
                    case 'gallery':
                        // slider
                        break
                    case 'video':

                        break
                    case 'big-video':
                        content = (
                            <video muted loop autoPlay playsInline>
                                <source src={videoFileMp4.mediaItemUrl} type="video/mp4" />
                            </video>
                        )
                        break
                }

                return (
                    <section key={index} data-scroll data-scroll-class={'scrolled-into-view'} data-scroll-offset={"5%, 5%"} data-scroll-repeat={false} className={`row ${type}`}>
                        { content}
                    </section>
                )
            })}
        </div>
    )
}